import q1 from '@assets/img/q1.png'
import q2 from '@assets/img/q2.png'
import q3 from '@assets/img/q3.png'
import q4 from '@assets/img/q4.png'
import q5 from '@assets/img/q5.png'
import q6 from '@assets/img/q6.png'
import q7 from '@assets/img/q7.png'
import q8 from '@assets/img/q8.png'
import q9 from '@assets/img/q9.png'
import q10 from '@assets/img/q10.png'
import q11 from '@assets/img/q11.png'
import q12 from '@assets/img/q12.png'

export const questions = [
    {
        id: 0,
        question: 'I hur många orter är Norrlands Bil verksamma?',
        questionImage: q1,
        options: [
            { text: 'a. 5 orter', isCorrect: false },
            { text: 'b. 8 orter', isCorrect: false },
            { text: 'c. 6 orter', isCorrect: false },
            { text: 'd. 7 orter', isCorrect: true }
        ]
    },
    {
        id: 1,
        question: 'Från vilket land härstammar varumärkets Skoda?',
        questionImage: q3,
        options: [
            { text: 'a. Tyskland', isCorrect: false },
            { text: 'b. Tjeckien', isCorrect: true },
            { text: 'c. Spanien', isCorrect: false },
            { text: 'd. Taiwan', isCorrect: false }
        ]
    },
    {
        id: 2,
        question: 'Hur många VW loggor finns på utsidan av nya ID7 Tourer?',
        questionImage: q4,
        options: [
            { text: 'a. 4', isCorrect: false },
            { text: 'b. 6', isCorrect: true },
            { text: 'c. 7', isCorrect: false },
            { text: 'd. 8', isCorrect: false }
        ]
    },
    {
        id: 3,
        question: 'En skönhet från 60-talet! Men vem är tillverkaren?',
        questionImage: q5,
        options: [
            { text: 'a. Volkswagen', isCorrect: false },
            { text: 'b. Skoda', isCorrect: false },
            { text: 'c. Seat', isCorrect: true },
            { text: 'd. Audi', isCorrect: false }
        ]
    },
    {
        id: 4,
        question: 'Vad heter Volkswagens största transportbilsmodell?',
        questionImage: q6,
        options: [
            { text: 'a. California', isCorrect: false },
            { text: 'b. Caravelle', isCorrect: false },
            { text: 'c. Crafter', isCorrect: true },
            { text: 'd. Transporter', isCorrect: false }
        ]
    },
    {
        id: 5,
        question: 'Hur många elmotorer har nya ID7 GTX?',
        questionImage: q7,
        options: [
            { text: 'a. 1', isCorrect: false },
            { text: 'b. 2', isCorrect: true },
            { text: 'c. 4', isCorrect: false },
            { text: 'd. 6', isCorrect: false }
        ]
    },
    {
        id: 6,
        question: 'Vem designade Volkswagens första bil? ',
        questionImage: q8,
        options: [
            { text: 'a. Herbert Schäfer', isCorrect: false },
            { text: 'b. Ferdinand Porsche', isCorrect: true },
            { text: 'c. Klaus Bischoff', isCorrect: false },
            { text: 'd. Andreas Mindt', isCorrect: false }
        ]
    },
    {
        id: 7,
        question: 'Hur många anställda har Norrlands bil? ',
        questionImage: q9,
        options: [
            { text: 'a. ca 280', isCorrect: false },
            { text: 'b. ca 400', isCorrect: true },
            { text: 'c. ca 320', isCorrect: false },
            { text: 'd. ca 360', isCorrect: false }
        ]
    },
    {
        id: 8,
        question: 'Hur många personbilar levererade Norrlands Bil 2023?',
        questionImage: q11,
        options: [
            { text: 'a. 3274 bilar', isCorrect: true },
            { text: 'b. 2549 bilar', isCorrect: false },
            { text: 'c. 1986 bilar', isCorrect: false },
            { text: 'd. 2914 bilar', isCorrect: false }
        ]
    }
]
